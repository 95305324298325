@font-face {
  font-family: "medium";
  src: url("NeueMontreal-Medium.woff");
  src: local("NeueMontreal Medium"), local("NeueMontreal-Medium"),
    url("NeueMontreal-Medium.woff2") format("woff2"),
    url("NeueMontreal-Medium.woff") format("woff"),
    url("NeueMontreal-Medium.ttf") format("truetype");
  font-weight: 530;
  font-style: normal;
}

@font-face {
  font-family: "regular";
  src: url("NeueMontreal-Regular.woff");
  src: local("NeueMontreal Regular"), local("NeueMontreal-Regular"),
    url("NeueMontreal-Regular.woff2") format("woff2"),
    url("NeueMontreal-Regular.woff") format("woff"),
    url("NeueMontreal-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "apoc";
  src: url("Arachne-Demonic.otf") format("opentype");
  font-weight: 400;
}
